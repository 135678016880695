<template>
  <div class="userList">
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="loveImg" label="公益项目头像" width="150">
          <template slot-scope="scope">
            <img :src="scope.row.loveImg" alt="" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column prop="loveTitle" label="公益项目名称"></el-table-column>
        <el-table-column prop="loveDescribe" label="公益项目描述"></el-table-column>
        <!-- <el-table-column
          prop="jumpAppid"
          label="公益项目appid"
        ></el-table-column>
        <el-table-column
          prop="jumpIndex"
          label="跳转页面路径"
        ></el-table-column>
        <el-table-column prop="yxbz" label="有效标志">
          <template slot-scope="scope">
            {{ scope.row.yxbz === "Y" ? "有效" : "无效" }}
          </template>
        </el-table-column> -->

        <el-table-column prop label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import UserDialog from "./components/dialog.vue";
export default {
  components: { UserDialog },
  data() {
    return {
      page: {
        page: 1,
        size: 10,
      },
      params: {},
      total: 0,
      tableData: [],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.size;
    },
    handleSizeChange(size) {
      this.page.size = size;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/home/pageLoveList",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
      this.$refs.dialog.initDialog();
    },
    editData(item) {
      this.$refs.dialog.getInfo(item);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax(
          "api/admin/home/love/delById",
          { id: item.id },
          "post"
        ).then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .leftBox {
    width: 220px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
